@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.active_header {
  background-color: #2d6ef5;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 420px) {
  .term-container {
    height: 150vh !important;
  }
}